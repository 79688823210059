var render = function () {
  var _vm$ck$driver, _vm$ck$kendaraan;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('header', {
    staticClass: "mb-2"
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "2",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Tanggal")])]), _c('p', [_vm._v(_vm._s(_vm.humanDate(_vm.ck.tanggal)))])]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Driver")])]), _c('p', [_vm._v(_vm._s((_vm$ck$driver = _vm.ck.driver) === null || _vm$ck$driver === void 0 ? void 0 : _vm$ck$driver.nama_lengkap))])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Keterangan")])]), _c('p', [_vm._v(_vm._s(_vm.ck.keterangan))])]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Kendaraan")])]), _c('p', [_vm._v(" " + _vm._s((_vm$ck$kendaraan = _vm.ck.kendaraan) !== null && _vm$ck$kendaraan !== void 0 && _vm$ck$kendaraan.nama_kendaraan ? _vm.ck.kendaraan.nama_kendaraan : "kosong") + " ")])])], 1)], 1)], 1), _c('main', [_c('b-card', {
    attrs: {
      "title": "Rincian Pengecekan"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.add($event);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v("Rincian Pengecekan ")], 1), _c('b-modal', {
    attrs: {
      "id": "modal-ubah",
      "cancel-variant": "secondary",
      "ok-title": "Ubah",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "info"
          },
          on: {
            "click": _vm.submit
          }
        }, [_vm._v(" Ubah ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Bagian"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.bagian_id
    },
    model: {
      value: _vm.form.bagian_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bagian_id", $$v);
      },
      expression: "form.bagian_id"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Pilih Kondisi"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.optkondisi
    },
    model: {
      value: _vm.form.kondisi,
      callback: function ($$v) {
        _vm.$set(_vm.form, "kondisi", $$v);
      },
      expression: "form.kondisi"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "head(checkbox)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function ($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.checkedList,
            callback: function ($$v) {
              _vm.checkedList = $$v;
            },
            expression: "checkedList"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function (row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function (row) {
        return [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Ubah',
            expression: "'Ubah'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function ($event) {
              return _vm.edit(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Hapus',
            expression: "'Hapus'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }